export const cleanEmptyObjectKeys = <T>(obj: T): T => {
  for (const propName in obj) {
    const value = obj[propName] as any
    if ([null, undefined, ''].includes(value)) {
      delete obj[propName]
    }
  }
  return obj
}

export async function sleepMs(timeMs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeMs)
  })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y
}

export const formatNumberToCurrency = (currency: string, n: number): string => {
  if (currency && currency === '$' && n > 0) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(n)
  }
  return currency + n?.toFixed(2)
}

export const range = (start: number, stop: number, step: number): Array<number> => {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
}

export const numberToCurrency = (number: number, currency: string): string => {
  if (currency && currency === '$' && Number.isFinite(number)) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })
      .format(number)
      .replace('$', '')
  } else if (currency && currency === '€' && Number.isFinite(number)) {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2 })
      .format(number)
      .replace('€', '')
  }

  return number && number.toFixed(2)
}

export const isElementVisibleOnViewport = (element: HTMLElement, percentage: number): boolean => {
  const rect = element.getBoundingClientRect()
  const windowHeight = window.innerHeight || document.documentElement.clientHeight

  return !(
    Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) < percentage ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentage
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const debounce = (callback: any, wait: number): any => {
  let debounceTimeout
  return (...props) => {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(() => {
      callback([...props])
    }, wait)
  }
}

export const setCookie = (name: string, value: string, ttl?: number, domain?: string): void => {
  let expires = ''
  let cookieDomain = ''
  if (ttl) {
    const date = new Date()
    date.setTime(date.getTime() + ttl * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  if (domain) {
    cookieDomain = '; domain=' + domain
  }
  document.cookie = name + '=' + escape(value) + expires + cookieDomain + '; path=/; samesite=lax'
}

export const getCookie = (name: string): string => {
  let i, c
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (i = 0; i < ca.length; i++) {
    c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length)
    }
    if (c.indexOf(nameEQ) === 0) {
      return unescape(c.substring(nameEQ.length, c.length))
    }
  }
  return null
}

export const clearCookie = (name: string): void => {
  setCookie(name, '', 0)
}
